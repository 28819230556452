<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!-- <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in with</small>
          </div>
          <div class="btn-wrapper text-center">
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/github.svg"
              /></span>
              <span class="btn-inner--text">Github</span>
            </a>
            <a href="#" class="btn btn-neutral btn-icon">
              <span class="btn-inner--icon"
                ><img src="img/icons/common/google.svg"
              /></span>
              <span class="btn-inner--text">Google</span>
            </a>
          </div>
        </div> -->
        <div class="card-body px-lg-5 py-lg-5">
          <!-- <div class="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div> -->
          <form role="form">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              type="email"
              addon-left-icon="ni ni-email-83"
              :value="model.email"
              @input="(event) => (model.email = event.target.value)"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              :value="model.password"
              @input="(event) => (model.password = event.target.value)"
            />

            <!-- <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox> -->
            <div class="text-center">
              <base-button type="primary" v-on:click="getLogin()" class="my-4">
                Sign-in
              </base-button>
            </div>
          </form>
          <div v-if="flag">
            <base-alert type="danger" dismissible>
              <span class="alert-inner--text">
                Email or Password is Incorrect</span
              >
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </base-alert>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div> -->
  </div>
</template>
<script>
import { ElSelect, ElOption } from "element-plus";
import { RepositoryFactory } from ".././repositories/RepositoryFactory";
const userRepository = RepositoryFactory.get("users");

export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "login",
  data() {
    return {
      flag: false,
      MemberData: [],
      model: {
        login: true,
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async getLogin() {
      var data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await userRepository.login(data);
      if (response.status == 200) {
        if (response.data.id) {
          localStorage.setItem("user", JSON.stringify(response.data));
          this.$router.push("dashboard");
        } else {
          this.flag = true;
          setTimeout(() => {
            this.flag = false;
          }, 3000);
        }
      }
    },
    getList() {
      userRepository.getUser().then((data) => {
        this.tableData = Object.freeze(data);
        this.MemberData = this.tableData.data;
      });
    },
  },
  mounted() {
    // this.getList();
  },
};
</script>
<style></style>
